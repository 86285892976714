import React from 'react'
import { Link } from 'gatsby'

import taxReturnImg from '../../images/svg/pricing/tax-return.svg'
import payrollImg from '../../images/svg/pricing/payroll.svg'
import companyRegImg from '../../images/svg/pricing/company-registration.svg'

import SiteLayout from '../../layout/SiteLayout'
import SEO from '../../components/SEO'
import Section from '../../components/Section'
import { Title, TitleContainer, TitleIntro } from '../../components/Title'
import { CtaCurve, CtaCurveTitle } from '../../components/CtaCurve'
import {
  SideBySide,
  SideBySideImg,
  SideBySideText,
  SideBySideTitle,
} from '../../components/SideBySide'

const StandaloneServices = () => {
  return (
    <SiteLayout header="light">
      <SEO title="Pricing" />
      <TitleContainer>
        <Title>Standalone Service Pricing</Title>
        <TitleIntro>
          We offer standalone services for businesses that don't need a full
          accounting package. Our standalone services are also great for
          landlords, businesses that are too big for our small business
          accounting service and individuals that just need a tax return.
        </TitleIntro>
      </TitleContainer>
      <Section>
        <SideBySide>
          <SideBySideImg src={taxReturnImg} alt="Tax return" />
          <SideBySideText>
            <SideBySideTitle>
              Personal Tax Returns from £99.00* per year
            </SideBySideTitle>
            <p>
              Our tax team can prepare your personal tax return accurately and
              on time to avoid unnecessary penalties. We'll calculate your
              income and claim all the tax reliefs you're entitled to.
            </p>
            <strong>Perfect for: </strong>
            <ul className="bullet-list">
              <li>Landlords with rental income</li>
              <li>Employees with high income</li>
              <li>Individuals with capital gains</li>
            </ul>
          </SideBySideText>
        </SideBySide>
        <SideBySide reverse>
          <SideBySideText>
            <SideBySideTitle>Payroll from £15.00* per month</SideBySideTitle>
            <p>
              Our monthly charges are £4 per monthly paid employee, £5 per
              fornightly paid employee and £6 per weekly paid employee. We have
              a minimum charge of £15 per month which includes 3 employees.
            </p>
            <strong>We'll do the following: </strong>
            <ul className="bullet-list">
              <li>Prepare payslips for you to pass on to your staff</li>
              <li>Calculate taxes and pension contributions due</li>
              <li>Submit payroll to HMRC and your pension provider</li>
              <li>Give you a simple report on what to pay and when</li>
            </ul>
          </SideBySideText>
          <SideBySideImg src={payrollImg} alt="Payroll" />
        </SideBySide>
        <SideBySide>
          <SideBySideImg src={companyRegImg} alt="Company registration" />
          <SideBySideText>
            <SideBySideTitle>Company registration from £99.00*</SideBySideTitle>
            <p>
              Register your new limited company at Companies House. We ensure
              that your company is setup correctly to get your new venture off
              to the best start.
            </p>
            <strong>You'll receive the following: </strong>
            <ul className="bullet-list">
              <li>Certificate of incorporation</li>
              <li>Share certificates</li>
              <li>First board minutes</li>
              <li>Articles and Memorandum of association</li>
            </ul>
          </SideBySideText>
        </SideBySide>
        <p className="text-center">*Prices exclude VAT at 20%</p>
      </Section>
      <CtaCurve white>
        <CtaCurveTitle>Interested in our standalone services?</CtaCurveTitle>
        <p>We can call you anytime between 9am - 5pm Monday to Friday.</p>
        <Link className="btn btn-primary-invert" to="/call-back">
          Request a call back
        </Link>
      </CtaCurve>
    </SiteLayout>
  )
}

export default StandaloneServices
